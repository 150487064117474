<template>
  <div class="cta-list" :class="{ darken: darken }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    darken: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
