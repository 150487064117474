<template>
  <div class="cta-list-item" :class="{ 'cta-list-item-darken': darken }">
    <div class="checkmark"></div>

    <p class="cta-list-item-title">
      {{ title }}

      <slot name="sup"></slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    darken: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
